import React, { useEffect, useContext } from "react";
import "./App.css";
import loadable from "@loadable/component";
import { Switch, BrowserRouter, Route } from "react-router-dom";
import { authProtectedRoutes, publicRoutes } from "./routes/";
import AppRoute from "./routes/route";
import ReactGA from "react-ga4";
// context
import { AppContext } from "./contexts/AppContext";
import JSCookie from "js-cookie";
const NotFound = loadable(() => import("./components/NotFound"));

function App() {
  // global state
  const {
    initialPublication,
    initialEdition,
    selectedNewsType,
    allowInterStitial,
  } = useContext(AppContext);

  useEffect(() => {
    if (initialPublication?.trackingId && initialEdition?.trackingId) {
      ReactGA.initialize([
        {
          trackingId: initialPublication?.trackingId,
        },
        {
          trackingId: initialEdition?.trackingId,
        },
      ]);
    }
  }, [
    initialPublication?.trackingId,
    initialEdition?.trackingId,
    selectedNewsType,
  ]);

  // useEffect(() => {
  //   const loadGAScript = () => {
  //     if (window && document) {
  //       const script = document.createElement("script");

  //       script.src = "https://www.googletagmanager.com/gtag/js?id=G-377BEZWZV9";

  //       script.async = true;

  //       document.body.appendChild(script);

  //       // An array of IDs I want to load on the same page(s) at the same time
  //       var googleIds = ["G-377BEZWZV9"];

  //       // Setting dataLayer & gtag to window because I'm using a custom code text field in a tag management system
  //       window.dataLayer = window.dataLayer || [];
  //       window.gtag =
  //         window.gtag ||
  //         function () {
  //           window.dataLayer.push(arguments);
  //         };
  //       window.gtag("js", new Date());
  //       window.gtag("config", "G-377BEZWZV9");

  //       // Flag used to ensure script only set with first ID, and rest of IDs are pushed to dataLayer
  //       var gtagScriptExists = false;

  //       // ID validation regex. Only tested with AW-*, but DC & UA are also valid prefixes
  //       var validIdStructure = new RegExp(/(AW|DC|UA)-[0-9]{8,}(-[0-9]{1,})?/);
  //     }
  //   };
  //   loadGAScript();
  // }, []);
  // if (window.location.pathname === "/") {
  //   if (JSCookie.get("pubindex") && JSCookie.get("edindex")) {
  //     window.location.assign(
  //       `${window.location.origin}/${publicationJson?.Publications?.[
  //         JSCookie.get("pubindex")
  //       ]?.publicationname
  //         ?.split(" ")
  //         .join("-")
  //         .toLowerCase()}/${publicationJson?.Publications?.[
  //         JSCookie.get("pubindex")
  //       ]?.editions[JSCookie.get("edindex")]?.editionname
  //         ?.split(" ")
  //         .join("-")
  //         .toLowerCase()}`
  //     );
  //   } else {
  //     window.location.assign(`${window.location.origin}/home`);
  //   }
  // }

  useEffect(() => {
    // console.log = function () {};
    console.warn = function () {};
    console.error = function () {};
    //👆 comment to show console.log.log.
  }, []);
  // useEffect(() => {
  //   window.location.replace("https://epaper.indiatimes.com");
  // }, []);
  return (
    <BrowserRouter>
      <Switch>
        {publicRoutes.map((route, idx) => (
          <AppRoute
            path={route.path}
            component={route.component}
            key={idx}
            isAuthProtected={false}
          />
        ))}

        {authProtectedRoutes.length > 0 &&
          authProtectedRoutes.map((route, idx) => (
            <AppRoute
              path={route.path}
              component={route.component}
              key={idx}
              isAuthProtected={true}
            />
          ))}
        <Route
          component={(props) => (
            <NotFound {...props} allowInterStitial={allowInterStitial} />
          )}
        />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
